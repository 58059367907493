<template>
  <v-card
    class="py-7 elevation-0 flex-column justify-center rounded-lg border-primary"
    width="100%"
    max-width="370px"
  >
    <div class="d-flex justify-start px-4 mb-5">
      <v-img
        contain
        max-height="41"
        max-width="41"
        :src="`/insuranceIcons/${service.icon}.svg`"
      ></v-img>
    </div>
    <v-card-title
      class="
        pt-0
        d-flex
        text-left
        justify-start
        font-weight-bold
        line-height-InsText
        checkout-title
        purple-dark--text
      "
      color="#302479"
      >{{ service.title }}</v-card-title
    >
    <v-card-text
      class="accent--text pb-0"
      :class="{ 'd-flex justify-center': $vuetify.breakpoint.smAndDown }"
    >
      <v-list
        dense
        flat
        class="font-size-card-text line-height-btn"
      >
        <v-list-item
          class="pa-0"
          v-for="(item, i) in service.items"
          :key="i"
          v-show="i < 3"
        >
          <v-list-item-content class="pa-0 purple-dim--text" v-text="item.text">
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <div class="flex-column d-flex align-start mt-3 justify-start">
      <v-btn
        v-if="service.code != 4682"
        @click.stop="dialog = true"
        text
        class="see-all-service text-none"
      >
        <img class="mr-2" max-width="30" src="/images/share.png" />
        {{ $t('petition.seeAllCoverage') }}</v-btn
      >
      <span
        v-format-price
        class="
          full-width
          text-left
          px-3
          font-size-title
          primary--text
          pt-4
          checkout-price
        "
        >{{ price }}</span
      >
      <span class="full-width primary--text text-left px-3 card-bottom-text py-0">{{
        $t('checkOut.annualPremiumConst')
      }}</span>
    </div>

    <v-dialog
      scrollable
      width="750"
      v-model="dialog"
      class="rounded-lg"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card
        class="card-class"
        flat
        :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title
          class="
            full-width
            d-flex
            accent-text
            card-title-width
            justify-space-between
            font-size-card-title
            line-height-InsText
          "
        >
          <span>{{ service.title }}</span>
          <v-btn text fab right absolute small @click="dialog = false">
            <v-icon color="#D0D1D4">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-subtitle
          v-if="service.code != 212 && service.code != 209"
          class="accent-text pt-4 font-size-card-text"
          >{{ $t('petition.cardSubheader') }}</v-card-subtitle
        >
        <v-card-subtitle
          v-if="service.code == 209"
          class="accent-text pt-4 font-size-card-text"
          >{{ $t('petition.cardSubheaderBuilding') }}</v-card-subtitle
        >
        <v-card-subtitle
          v-if="service.code == 212"
          class="accent-text pt-4 font-size-card-text"
          >{{ $t('petition.cardSubheaderContent') }}</v-card-subtitle
        >
        <v-card-text>
          <v-list
            dense
            flat
            class="font-size-card-text accent-text line-height-btn"
          >
            <v-list-item v-for="(item, i) in service.items" :key="i">
              <v-list-item-icon class="mr-3">
                <v-icon color="checks">mdi-check-bold</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-text="item.text">
              </v-list-item-content>
            </v-list-item> </v-list
        ></v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CheckoutCard',
  props: {
    service: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapGetters(['price']),
  },
};
</script>

<style>
.checkout-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 127%;
  color: #2c2945;
}

.checkout-price {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 120%;
  color: #49d69f;
}
.border-primary {
  border: 1px solid #613CF8 !important;
}
.purple-dim--text {
  color: #6C6FB7 !important;
}
</style>
