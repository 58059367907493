<template>
  <StepperLayout>
    <v-col cols="12">
      <v-row justify="center" align="center" class="px-10">
        <v-col
          cols="12"
          class="
            accent--text
            font-weight-title font-size-InsTitle
            line-height-InsText
            px-0
            my-6
            mb-3
            container
            text-center
          "
          ><div :class="{ 'pt-10': $vuetify.breakpoint.mdAndUp }">
            <h2 v-html="$t('checkOut.yourOfferIsReady')" class="petition-title"></h2>
          </div></v-col
        >
        <v-row justify="center" cols="12" class="px-0 container my-6 purple-dim--text">
          <v-col
            class="d-flex flex-column mb-5 pl-0 align-center"
            lg="3"
            cols="12"
          >
            <CheckoutCard v-if="checkoutServices" :service="checkoutServices">
            </CheckoutCard>
            <span
              class="card-span text-center text-lg-left full-width mt-5 purple-dark--text"
              v-if="
                petitionServices.length &&
                  petition.hasProtection &&
                  petition.code == 4682
              "
              >{{ $t('checkOut.addLegalProtection') }}</span
            >

            <petition-card
              class="mt-5"
              :service="petitionServices[0]"
              v-if="
                petitionServices.length &&
                  petition.hasProtection &&
                  petition.code == 4682
              "
            ></petition-card>
          </v-col>
          <v-col class="pl-lg-10 pl-4" lg="9" cols="12">
            <div class="font-size-text mb-6 line-height-title">
              {{ $t('advisorForm.accordingToYourNeeds') }}
            </div>
            <div class="terms-text purple-dark--text font-weight-bold font-size-title mb-2 line-height-title">
              {{ $t('checkOut.acceptanceOfTerms') }}
            </div>
            <v-list flat subheader three-line>
              <v-list-item v-if="petition.code == 4682">
                <template #default>
                  <v-list-item-action class="mr-1 mt-3">
                    <v-checkbox v-model="checkbox.protection"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title class="purple-dim--text check-out-text">
                      {{ $t('checkOut.iDeclareThat') }}</v-list-item-title
                    >
                    <div class="purple-dim--text check-out-text">
                      <ul>
                        <li>
                          {{ $t('checkOut.thePropertyIsLeasedToAThirdParty') }}
                        </li>
                        <li>
                          {{ $t('checkOut.thePropertyIsNotAvailableToAThirdParty') }}
                        </li>
                        <li>
                          {{ $t('checkOut.thePropertyIsNotAvailableForShortTermLease') }}
                        </li>
                        <li>
                          {{ $t('checkOut.theRentOfThePropertyHasBeenDeclaredTaxOffice') }}
                        </li>
                        <li>
                          {{ $t('checkOut.theRealEstateLeaseContractTerminated') }}
                        </li>
                      </ul>
                    </div>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-list-item v-if="petition.code != 4682">
                <template #default>
                  <v-list-item-action class="mr-1 mt-3">
                    <v-checkbox v-model="checkbox.property"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title class="purple-dim--text check-out-text">
                      {{ $t('checkOut.iDeclareThat') }}</v-list-item-title
                    >
                    <div class="purple-dim--text check-out-text">
                      <ul>
                        <li>
                          {{ $t('checkOut.thePropertyHasBeenConstructedWithLegalPermit') }}
                        </li>
                        <li>
                          {{ $t('checkOut.theFrameOfTheProperty') }}
                        </li>
                        <li>
                          {{ $t('checkOut.thereIsNotPreviousDamageToTheProperty') }}
                        </li>
                        <li>
                          {{ $t('checkOut.theHouseDoesNotRemainVacant') }}
                        </li>
                        <li>
                          {{ $t('checkOut.thePropertyIsNotAvailableToTenants') }}
                        </li>
                      </ul>
                    </div>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item>
                <template #default>
                  <v-list-item-action class="mr-1 mt-4">
                    <v-checkbox v-model="checkbox.terms"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title class="purple-dim--text check-out-text">
                    </v-list-item-title>
                    <div class="check-out-text purple-dim--text check-out-text">
                      {{ $t('checkOut.iDeclareThat') }}
                      <span class="font-weight-bold purple-dim--text"
                        >{{ $t('checkOut.iHaveReadAndAccept') }}
                      </span>
                      {{ $t('checkOut.their') }}
                      <a
                        :href="checkoutServices.termsLink"
                        target="_blank"
                        class="text-decoration-underline purple-dim--text"
                        >{{ $t('checkOut.insuranceTerms') }} ({{
                          checkoutServices.title
                        }})</a
                      >
                      <a
                        v-if="
                          petitionServices.length &&
                            petition.hasProtection &&
                            petition.code == 4682 &&
                            petitionServices[0].termsLink
                        "
                        :href="petitionServices[0].termsLink"
                        target="_blank"
                        class="text-decoration-underline purple-dim--text"
                        >,{{ $t('checkOut.insuranceTerms') }} ({{
                          petitionServices[0].title
                        }})</a
                      >
                      ,{{ $t('checkOut.the') }}
                      <a
                        :href="checkoutServices.newsLink"
                        target="_blank"
                        class="text-decoration-underline purple-dim--text"
                        >{{ $t('checkOut.prospectus') }} ({{
                          checkoutServices.title
                        }})</a
                      >

                      <a
                        v-if="
                          petitionServices.length &&
                            petition.hasProtection &&
                            petition.code == 4682
                        "
                        :href="petitionServices[0].newsLink"
                        target="_blank"
                        class="text-decoration-underline purple-dim--text"
                        >,{{ $t('checkOut.prospectus') }} ({{
                          petitionServices[0].title
                        }})</a
                      >
                      {{ $t('checkOut.andIHaveBeenInformedAboutTheCharacteristics') }}
                      <a
                        v-if="petitionServices[0] && petition.hasProtection"
                        :href="petitionServices[0].fileLink"
                        target="_blank"
                        class="
                          text-decoration-underline
                          purple-dim--text
                          check-out-text
                        "
                        >{{ $t('checkOut.informationDocument') }} ({{
                          petitionServices[0].title
                        }}),
                      </a>
                      <a
                        :href="checkoutServices.fileLink"
                        target="_blank"
                        class="
                          text-decoration-underline
                          purple-dim--text
                          check-out-text
                        "
                        >{{ $t('checkOut.informationDocument') }} ({{
                          checkoutServices.title
                        }}) </a
                      >.
                      {{ $t('checkOut.iConfirmThatAllTheAboveInformation') }}
                    </div>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-list-item>
                <template>
                  <v-list-item-action class="mr-1 mt-3"> </v-list-item-action>

                  <v-list-item-content>
                    <span
                      v-html="$t('checkOut.throughProspectusForTheProcessingOfPersonalData')"
                      class="purple-dim--text check-out-text"
                    ></span>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item>
                <template #default>
                  <v-list-item-action class="mr-1 mt-3">
                    <v-checkbox v-model="checkbox.agreement"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <div class="purple-dim--text check-out-text">
                      {{ $t('checkOut.iGiveMyExplicitConsent') }}
                    </div>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-list-item class="mb-5">
                <template>
                  <v-list-item-action class="mr-1 mt-3"> </v-list-item-action>

                  <v-list-item-content>
                    <div class="purple-dim--text check-out-text">
                      {{ $t('checkOut.mandatoryInformationFromLawNumber') }}
                      <a
                        href="/pdf/FORMA_TAYTOTITAS_ASFALISTIKOY_DIAMESOLAVITI_.pdf"
                        target="_blank"
                        class="
                          text-decoration-underline
                          purple-dim--text
                          check-out-text
                        "
                        >{{ $t('checkOut.here') }}</a
                      >
                    </div>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-row>
      <v-col cols="12" class="container mt-md-15 px-0">
        <v-col>
          <div class="d-flex justify-space-between px-0">
            <v-btn
              text
              @click="previousPage()"
              large
              outlined
              color="primary"
              class="
                d-flex
                justify-center
                px-4 px-md-8
                text-none
                rounded-xl
                back-btn
              "
            >
              <v-icon>mdi-chevron-left</v-icon>
              <span>{{ $t('back') }}</span>
            </v-btn>

            <v-btn
              right
              text
              :loading="loading"
              @click="nextPage()"
              large
              class="
                petition-btn-border
                primary
                px-3 px-md-8
                text-none
                rounded-xl
              "
              :class="{
                'petition-btn-border secondary px-6 px-md-8 text-none rounded-xl':
                  $vuetify.breakpoint.smAndDown,
              }"
            >
              <span v-if="!$vuetify.breakpoint.smAndDown">{{
                $t('checkOut.sendApplication')
              }}</span>
              <span v-else>{{ $t('send') }}</span>
            </v-btn>
          </div></v-col
        >
      </v-col>

      <v-snackbar
        rounded="lg"
        elevation="0"
        top
        color="#FFF0EF"
        class="error-snackbar"
        light
        v-model="snackbar"
      >
        <v-icon color="error">mdi-information-outline</v-icon
        ><span class="error--text ml-1"> {{ $t('checkOut.youNeedToAcceptTheTerms') }}</span>
      </v-snackbar>
    </v-col>
  </StepperLayout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CheckoutCard from '@/components/cards/CheckoutCard.vue';
import PetitionCard from '@/components/cards/PetitionCard.vue';
import StepperLayout from '@/components/layouts/StepperLayout';

export default {
  name: 'Checkout',
  data: () => ({
    snackbarText: '',
    snackbar: false,
    snackbarSuccess: true,
    checkbox: {
      property: false,
      terms: false,
      agreement: false,
      protection: false,
    },
    dialog: false,
    checkoutServices: {},
    petitionServices: [],
  }),
  components: { CheckoutCard, PetitionCard, StepperLayout },
  computed: {
    ...mapGetters(['petition', 'loading']),
  },

  methods: {
    ...mapMutations(['setLoading', 'moveToNextStep', 'moveToPreviousStep']),

    previousPage() {
      this.moveToPreviousStep();

      this.$router.back();
    },

    nextPage() {
      if (
        this.petition.code != 4682 &&
        (!this.checkbox.terms || !this.checkbox.property)
      ) {
        this.snackbarText = this.$t('checkOut.youNeedToAcceptTheTerms');
        return (this.snackbar = true);
      }
      if (
        this.petition.code == 4682 &&
        (!this.checkbox.terms || !this.checkbox.protection)
      ) {
        this.snackbarText = this.$t('checkOut.youNeedToAcceptTheTerms');
        return (this.snackbar = true);
      }

      this.setLoading(true);

      this.$axios
        .post(`get-policy-service`, this.petition)
        .then(() => {
          window.dataLayer.push({
            event: 'insurance_form',
            insurance_plan: this.checkoutServices.title,
          });

          if (this.petition.hasProtection && this.petition.code == 4682) {
            return this.$axios
              .post(`get-policy-service?legal=true`, {
                ...this.petition,
                code: 3030,
              })
              .then(() => {
                window.dataLayer.push({
                  event: 'insurance_form',
                  insurance_plan: this.petitionServices[0].title,
                });
                this.moveToNextStep();
                this.$router.push({ name: 'OrderPlaced' });
                this.setLoading(false);
              })
              .catch(err => {
                if (err.response.data) {
                  this.setLoading(false);
                  this.snackbarText = err.response.data;
                  return (this.snackbar = true);
                }
                this.snackbarText = this.$t('advisorForm.errorThereWasAProblemWithTheServer');
                this.setLoading(false);
                return (this.snackbar = true);
              });
          }

          this.setLoading(false);
          this.moveToNextStep();
          this.$router.push({ name: 'OrderPlaced' });
        })
        .catch(err => {
          if (err.response.data) {
            this.setLoading(false);
            this.snackbarText = err.response.data;
            return (this.snackbar = true);
          }
          this.snackbarText = this.$t('advisorForm.errorThereWasAProblemWithTheServer');
          this.setLoading(false);
          return (this.snackbar = true);
        });
    },
  },
  mounted() {
    this.snackbarSuccess = true;
    this.$axios
      .get('petition')
      .then(({ data }) => (this.petitionServices = data));
    if (this.petition.renter == 2) {
      this.$axios.get('home-services').then(({ data }) => {
        const [service] = data;
        this.checkoutServices = service;
      });
    } else {
      this.$axios
        .get(`services/${this.petition.code}`)
        .then(({ data }) => (this.checkoutServices = data));
    }
  },
};
</script>

<style>
.card-span {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 127%;
  color: #2c2945;
}
.card-span-package {
  font-style: normal;
  font-size: 18px;
  line-height: 127%;
  color: #2c2945;
}

.terms-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #2c2945;
}
.theme--light.v-list {
  background: transparent !important;
}
</style>
